/* stylelint-disable */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
}
[tabindex='-1']:focus {
  outline: none;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}

[type='text'],input:where(:not([type])),[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb;
}

input::-moz-placeholder, textarea::-moz-placeholder{
    color: #6b7280;
    opacity: 1;
}

input::placeholder,textarea::placeholder{
    color: #6b7280;
    opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper{
    padding: 0;
}

::-webkit-date-and-time-value{
    min-height: 1.5em;
    text-align: inherit;
}

::-webkit-datetime-edit{
    display: inline-flex;
}

::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field{
    padding-top: 0;
    padding-bottom: 0;
}

select{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
}

[multiple],[size]:where(select:not([size="1"])){
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: 0.75rem;
    -webkit-print-color-adjust: unset;
            print-color-adjust: unset;
}

[type='checkbox'],[type='radio']{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000;
}

[type='checkbox']{
    border-radius: 0px;
}

[type='radio']{
    border-radius: 100%;
}

[type='checkbox']:focus,[type='radio']:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type='checkbox']:checked,[type='radio']:checked{
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

[type='checkbox']:checked{
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {

    [type='checkbox']:checked{
        -webkit-appearance: auto;
           -moz-appearance: auto;
                appearance: auto;
    }
}

[type='radio']:checked{
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {

    [type='radio']:checked{
        -webkit-appearance: auto;
           -moz-appearance: auto;
                appearance: auto;
    }
}

[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus{
    border-color: transparent;
    background-color: currentColor;
}

[type='checkbox']:indeterminate{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

@media (forced-colors: active) {

    [type='checkbox']:indeterminate{
        -webkit-appearance: auto;
           -moz-appearance: auto;
                appearance: auto;
    }
}

[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus{
    border-color: transparent;
    background-color: currentColor;
}

[type='file']{
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit;
}

[type='file']:focus{
    outline: 1px solid ButtonText;
    outline: 1px auto -webkit-focus-ring-color;
}

body{
    font-family: Noto Sans JP, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.625;
    letter-spacing: 0.05em;
}

input:focus {
    box-shadow: none !important;
    outline: none !important;
  }

input[type='text']{
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

input[type='email']{
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

::-webkit-scrollbar {
    display: none;
  }

html {
    scrollbar-width: none;
  }

*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
.pointer-events-none{
    pointer-events: none !important;
}
.pointer-events-auto{
    pointer-events: auto !important;
}
.visible{
    visibility: visible !important;
}
.fixed{
    position: fixed !important;
}
.absolute{
    position: absolute !important;
}
.relative{
    position: relative !important;
}
.sticky{
    position: sticky !important;
}
.inset-0{
    inset: 0px !important;
}
.inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
}
.-right-1{
    right: -0.25rem !important;
}
.bottom-0{
    bottom: 0px !important;
}
.bottom-12{
    bottom: 3rem !important;
}
.bottom-14{
    bottom: 3.5rem !important;
}
.bottom-6{
    bottom: 1.5rem !important;
}
.bottom-7{
    bottom: 1.75rem !important;
}
.bottom-9{
    bottom: 2.25rem !important;
}
.left-0{
    left: 0px !important;
}
.right-0{
    right: 0px !important;
}
.right-\[400px\]{
    right: 400px !important;
}
.top-1\/2{
    top: 50% !important;
}
.top-\[300px\]{
    top: 300px !important;
}
.z-10{
    z-index: 10 !important;
}
.z-50{
    z-index: 50 !important;
}
.-m-6{
    margin: -1.5rem !important;
}
.m-0{
    margin: 0px !important;
}
.m-1{
    margin: 0.25rem !important;
}
.mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}
.mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.-mt-4{
    margin-top: -1rem !important;
}
.-mt-6{
    margin-top: -1.5rem !important;
}
.mb-0{
    margin-bottom: 0px !important;
}
.mb-0\.5{
    margin-bottom: 0.125rem !important;
}
.mb-2{
    margin-bottom: 0.5rem !important;
}
.mb-3{
    margin-bottom: 0.75rem !important;
}
.mb-4{
    margin-bottom: 1rem !important;
}
.mb-6{
    margin-bottom: 1.5rem !important;
}
.mb-8{
    margin-bottom: 2rem !important;
}
.ml-1{
    margin-left: 0.25rem !important;
}
.ml-3{
    margin-left: 0.75rem !important;
}
.ml-4{
    margin-left: 1rem !important;
}
.ml-6{
    margin-left: 1.5rem !important;
}
.ml-8{
    margin-left: 2rem !important;
}
.ml-\[300px\]{
    margin-left: 300px !important;
}
.ml-auto{
    margin-left: auto !important;
}
.mr-0{
    margin-right: 0px !important;
}
.mr-1{
    margin-right: 0.25rem !important;
}
.mr-2{
    margin-right: 0.5rem !important;
}
.mt-0{
    margin-top: 0px !important;
}
.mt-1{
    margin-top: 0.25rem !important;
}
.mt-10{
    margin-top: 2.5rem !important;
}
.mt-12{
    margin-top: 3rem !important;
}
.mt-2{
    margin-top: 0.5rem !important;
}
.mt-3{
    margin-top: 0.75rem !important;
}
.mt-4{
    margin-top: 1rem !important;
}
.mt-6{
    margin-top: 1.5rem !important;
}
.mt-8{
    margin-top: 2rem !important;
}
.block{
    display: block !important;
}
.inline{
    display: inline !important;
}
.flex{
    display: flex !important;
}
.grid{
    display: grid !important;
}
.contents{
    display: contents !important;
}
.hidden{
    display: none !important;
}
.size-20{
    width: 5rem !important;
    height: 5rem !important;
}
.size-3{
    width: 0.75rem !important;
    height: 0.75rem !important;
}
.size-4{
    width: 1rem !important;
    height: 1rem !important;
}
.size-5{
    width: 1.25rem !important;
    height: 1.25rem !important;
}
.size-6{
    width: 1.5rem !important;
    height: 1.5rem !important;
}
.size-\[56px\]{
    width: 56px !important;
    height: 56px !important;
}
.size-full{
    width: 100% !important;
    height: 100% !important;
}
.h-12{
    height: 3rem !important;
}
.h-14{
    height: 3.5rem !important;
}
.h-16{
    height: 4rem !important;
}
.h-20{
    height: 5rem !important;
}
.h-40{
    height: 10rem !important;
}
.h-5{
    height: 1.25rem !important;
}
.h-60{
    height: 15rem !important;
}
.h-8{
    height: 2rem !important;
}
.h-\[56px\]{
    height: 56px !important;
}
.h-fit{
    height: -moz-fit-content !important;
    height: fit-content !important;
}
.h-full{
    height: 100% !important;
}
.min-h-52{
    min-height: 13rem !important;
}
.min-h-full{
    min-height: 100% !important;
}
.min-h-screen{
    min-height: 100vh !important;
}
.w-0{
    width: 0px !important;
}
.w-1\/2{
    width: 50% !important;
}
.w-12{
    width: 3rem !important;
}
.w-20{
    width: 5rem !important;
}
.w-28{
    width: 7rem !important;
}
.w-36{
    width: 9rem !important;
}
.w-40{
    width: 10rem !important;
}
.w-44{
    width: 11rem !important;
}
.w-5{
    width: 1.25rem !important;
}
.w-56{
    width: 14rem !important;
}
.w-60{
    width: 15rem !important;
}
.w-64{
    width: 16rem !important;
}
.w-72{
    width: 18rem !important;
}
.w-96{
    width: 24rem !important;
}
.w-\[120px\]{
    width: 120px !important;
}
.w-auto{
    width: auto !important;
}
.w-fit{
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.w-full{
    width: 100% !important;
}
.min-w-0{
    min-width: 0px !important;
}
.max-w-2xl{
    max-width: 42rem !important;
}
.max-w-3xl{
    max-width: 48rem !important;
}
.max-w-5xl{
    max-width: 64rem !important;
}
.max-w-sm{
    max-width: 24rem !important;
}
.flex-1{
    flex: 1 1 0% !important;
}
.shrink-0{
    flex-shrink: 0 !important;
}
.grow{
    flex-grow: 1 !important;
}
.translate-y-0{
    --tw-translate-y: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-2{
    --tw-translate-y: 0.5rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-not-allowed{
    cursor: not-allowed !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.flex-col{
    flex-direction: column !important;
}
.flex-wrap{
    flex-wrap: wrap !important;
}
.flex-nowrap{
    flex-wrap: nowrap !important;
}
.items-start{
    align-items: flex-start !important;
}
.items-center{
    align-items: center !important;
}
.justify-start{
    justify-content: flex-start !important;
}
.justify-end{
    justify-content: flex-end !important;
}
.justify-center{
    justify-content: center !important;
}
.justify-between{
    justify-content: space-between !important;
}
.gap-4{
    gap: 1rem !important;
}
.gap-6{
    gap: 1.5rem !important;
}
.gap-x-1{
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
}
.gap-x-10{
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
}
.gap-x-2{
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
}
.gap-x-3{
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
}
.gap-x-4{
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
}
.gap-x-6{
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
}
.gap-x-8{
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
}
.gap-y-1{
    row-gap: 0.25rem !important;
}
.gap-y-2{
    row-gap: 0.5rem !important;
}
.gap-y-4{
    row-gap: 1rem !important;
}
.gap-y-8{
    row-gap: 2rem !important;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}
.divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}
.divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgb(243 244 246 / var(--tw-divide-opacity)) !important;
}
.overflow-hidden{
    overflow: hidden !important;
}
.overflow-x-auto{
    overflow-x: auto !important;
}
.text-wrap{
    text-wrap: wrap !important;
}
.text-nowrap{
    text-wrap: nowrap !important;
}
.break-words{
    overflow-wrap: break-word !important;
}
.rounded-lg{
    border-radius: 0.5rem !important;
}
.rounded-md{
    border-radius: 0.375rem !important;
}
.rounded-none{
    border-radius: 0px !important;
}
.rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.border-0{
    border-width: 0px !important;
}
.border-y{
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
}
.border-b{
    border-bottom-width: 1px !important;
}
.border-t{
    border-top-width: 1px !important;
}
.border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
.border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}
.border-gray-900\/5{
    border-color: rgb(17 24 39 / 0.05) !important;
}
.bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}
.bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity)) !important;
}
.bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}
.bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity)) !important;
}
.bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}
.bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}
.bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity)) !important;
}
.p-4{
    padding: 1rem !important;
}
.px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
}
.px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.pb-8{
    padding-bottom: 2rem !important;
}
.pl-0{
    padding-left: 0px !important;
}
.pl-3{
    padding-left: 0.75rem !important;
}
.pl-6{
    padding-left: 1.5rem !important;
}
.pr-0{
    padding-right: 0px !important;
}
.pr-3{
    padding-right: 0.75rem !important;
}
.pr-4{
    padding-right: 1rem !important;
}
.pt-0{
    padding-top: 0px !important;
}
.pt-0\.5{
    padding-top: 0.125rem !important;
}
.pt-5{
    padding-top: 1.25rem !important;
}
.pt-6{
    padding-top: 1.5rem !important;
}
.text-center{
    text-align: center !important;
}
.text-start{
    text-align: start !important;
}
.text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
}
.text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
}
.text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
}
.text-\[18px\]{
    font-size: 18px !important;
}
.text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}
.text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}
.text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}
.text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
}
.text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}
.font-bold{
    font-weight: 700 !important;
}
.font-medium{
    font-weight: 500 !important;
}
.font-normal{
    font-weight: 400 !important;
}
.font-semibold{
    font-weight: 600 !important;
}
.leading-4{
    line-height: 1rem !important;
}
.leading-6{
    line-height: 1.5rem !important;
}
.leading-7{
    line-height: 1.75rem !important;
}
.leading-none{
    line-height: 1 !important;
}
.tracking-tight{
    letter-spacing: -0.025em !important;
}
.tracking-tighter{
    letter-spacing: -0.05em !important;
}
.text-accent{
    --tw-text-opacity: 1 !important;
    color: rgb(52 211 153 / var(--tw-text-opacity)) !important;
}
.text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
}
.text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgb(37 99 235 / var(--tw-text-opacity)) !important;
}
.text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}
.text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}
.text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
}
.text-primary{
    --tw-text-opacity: 1 !important;
    color: rgb(26 25 38 / var(--tw-text-opacity)) !important;
}
.text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}
.text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgb(250 204 21 / var(--tw-text-opacity)) !important;
}
.text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgb(161 98 7 / var(--tw-text-opacity)) !important;
}
.text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgb(133 77 14 / var(--tw-text-opacity)) !important;
}
.underline{
    text-decoration-line: underline !important;
}
.no-underline{
    text-decoration-line: none !important;
}
.opacity-0{
    opacity: 0 !important;
}
.opacity-100{
    opacity: 1 !important;
}
.shadow{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-md{
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}
.ring-black\/5{
    --tw-ring-color: rgb(0 0 0 / 0.05) !important;
}
.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.duration-100{
    transition-duration: 100ms !important;
}
.duration-300{
    transition-duration: 300ms !important;
}
.ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}
.ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}
.custom-menu .ant-menu-item {
    display: flex !important;
    align-items: center !important;
  }
.custom-menu .ant-menu-submenu-title {
    display: flex !important;
    align-items: center !important;
  }
.custom-menu .ant-menu-submenu .ant-menu-item {
    display: flex !important;
    align-items: center !important;
  }
.customDatePicker .ant-picker-input > input {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
.customDashboardDatePicker .ant-picker-input > input {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
.customDescriptions .ant-descriptions-item {
    padding-bottom: 0 !important;
  }
.vertical-text {
    writing-mode: vertical-rl !important;
    text-orientation: upright !important;
    letter-spacing: 8px !important;
    transition: all 0.3s !important;
  }
.hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity)) !important;
}
.hover\:text-accent:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(52 211 153 / var(--tw-text-opacity)) !important;
}
.hover\:text-accent-hover:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(77 224 179 / var(--tw-text-opacity)) !important;
}
@media (min-width: 640px){

    .sm\:col-span-2{
        grid-column: span 2 / span 2 !important;
    }

    .sm\:col-span-3{
        grid-column: span 3 / span 3 !important;
    }

    .sm\:col-span-4{
        grid-column: span 4 / span 4 !important;
    }

    .sm\:col-span-6{
        grid-column: span 6 / span 6 !important;
    }

    .sm\:m-0{
        margin: 0px !important;
    }

    .sm\:mx-7{
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }

    .sm\:mx-auto{
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .sm\:my-0{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .sm\:-mb-0{
        margin-bottom: -0px !important;
    }

    .sm\:-mb-0\.5{
        margin-bottom: -0.125rem !important;
    }

    .sm\:mb-6{
        margin-bottom: 1.5rem !important;
    }

    .sm\:mr-6{
        margin-right: 1.5rem !important;
    }

    .sm\:mt-0{
        margin-top: 0px !important;
    }

    .sm\:block{
        display: block !important;
    }

    .sm\:flex{
        display: flex !important;
    }

    .sm\:w-fit{
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .sm\:w-full{
        width: 100% !important;
    }

    .sm\:max-w-sm{
        max-width: 24rem !important;
    }

    .sm\:grow-0{
        flex-grow: 0 !important;
    }

    .sm\:translate-x-0{
        --tw-translate-x: 0px !important;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    }

    .sm\:translate-x-2{
        --tw-translate-x: 0.5rem !important;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    }

    .sm\:translate-y-0{
        --tw-translate-y: 0px !important;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    }

    .sm\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .sm\:grid-cols-6{
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
    }

    .sm\:justify-normal{
        justify-content: normal !important;
    }

    .sm\:gap-x-2{
        -moz-column-gap: 0.5rem !important;
             column-gap: 0.5rem !important;
    }

    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
    }

    .sm\:px-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .sm\:px-6{
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .sm\:px-7{
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important;
    }

    .sm\:text-start{
        text-align: start !important;
    }

    .sm\:tracking-tight{
        letter-spacing: -0.025em !important;
    }
}
@media (min-width: 768px){

    .md\:mx-14{
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }

    .md\:ml-6{
        margin-left: 1.5rem !important;
    }

    .md\:mr-12{
        margin-right: 3rem !important;
    }

    .md\:block{
        display: block !important;
    }

    .md\:flex{
        display: flex !important;
    }

    .md\:w-fit{
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .md\:flex-nowrap{
        flex-wrap: nowrap !important;
    }

    .md\:justify-normal{
        justify-content: normal !important;
    }

    .md\:px-14{
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }
}
@media (min-width: 1024px){

    .lg\:mx-0{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .lg\:block{
        display: block !important;
    }

    .lg\:flex{
        display: flex !important;
    }

    .lg\:w-0{
        width: 0px !important;
    }

    .lg\:w-64{
        width: 16rem !important;
    }

    .lg\:max-w-none{
        max-width: none !important;
    }

    .lg\:flex-auto{
        flex: 1 1 auto !important;
    }

    .lg\:flex-none{
        flex: none !important;
    }

    .lg\:grid-cols-3{
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }

    .lg\:items-center{
        align-items: center !important;
    }

    .lg\:justify-between{
        justify-content: space-between !important;
    }

    .lg\:gap-x-16{
        -moz-column-gap: 4rem !important;
             column-gap: 4rem !important;
    }

    .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
    }

    .lg\:border-0{
        border-width: 0px !important;
    }

    .lg\:px-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .lg\:px-8{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}
.\[\&_div\]\:h-full div{
    height: 100% !important;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_36bd41';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_36bd41';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_36bd41 {font-family: '__Inter_36bd41', '__Inter_Fallback_36bd41';font-style: normal
}

