@import 'antd/dist/reset.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-ja leading-relaxed tracking-wider;
  }
  input:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  input[type='text'] {
    @apply border border-gray-300 rounded-md;
  }
  input[type='email'] {
    @apply border border-gray-300 rounded-md;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  html {
    scrollbar-width: none;
  }
}

@layer utilities {
  .bg-gradient-diagonal-lines {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2px,
      rgba(229, 231, 235, 0.5) 2px,
      rgba(229, 231, 235, 0.5) 4px
    );
    background-size: 40px 40px;
  }
  .custom-select .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }
  .custom-select .ant-select-selector:hover,
  .custom-select .ant-select-selector:focus {
    border-right-width: 0.5px !important;
  }

  .custom-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0.5px !important;
  }

  .custom-button-revert {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }

  .custom-button-revert:not(:disabled):hover {
    border-right-width: 0.5px !important;
    border-right-color: #34d399 !important;
  }

  .dropdown-button {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
  }

  .dropdown-button:hover {
    border-left-width: 1px;
  }

  .custom-tabs .ant-tabs-tab {
    font-size: 14px;
    border-bottom-color: #e5e7eb;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .custom-row-class td {
    background-color: #f3f4f6;
  }

  .cell-boder:first-child {
    border-left: 1px solid #e5e7eb;
  }

  .custom-credit-card {
    background-color: #1a1a1a;
    color: white;
    border-radius: 15px;
    width: 420px;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    padding: 20px;
    font-size: 18px;
    aspect-ratio: 1.586;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-logo-background {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%; /* ロゴの幅 */
    height: 100%; /* ロゴの高さ */
    background-image: url('/images/logo/compact-transparency.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0.475;
  }

  .credit-card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .credit-card-chip {
    background: url('/images/credit-card-chip.png') no-repeat center center;
    background-size: contain;
    width: 40px;
    height: 30px;
  }

  .credit-card-logo {
    font-size: 24px;
    font-weight: bold;
  }

  .credit-card-balance {
    position: absolute;
    bottom: 20px;
    left: 46px;
    margin-top: 20px;
    font-size: 14px;
  }

  .balance-amount {
    font-size: 28px;
    font-weight: bold;
  }

  .credit-card-brand {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 24px;
    background: url('/images/visa.png') no-repeat center center;
    background-size: contain;
  }

  .custom-menu .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .custom-menu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .custom-menu .ant-menu-submenu .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .customDatePicker .ant-picker-input > input {
    font-size: 24px;
    font-weight: 700;
  }

  .customDashboardDatePicker .ant-picker-input > input {
    font-size: 18px;
    font-weight: 700;
  }

  .customRangePicker .ant-picker-input > input {
    font-size: 24px;
    font-weight: 700;
  }

  .customRangePicker .ant-picker-separator {
    font-size: 24px;
    font-weight: 700;
  }

  .customDescriptions .ant-descriptions-item {
    padding-bottom: 0;
  }

  .vertical-text {
    writing-mode: vertical-rl;
    text-orientation: upright;
    letter-spacing: 8px;
    transition: all 0.3s;
  }
}
